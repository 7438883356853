import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "glossaryresults", "searchform" ]

  connect() {
  }


  search() {
    console.log("wwww")
    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
    this.searchformTarget.commit.click();
    }, 200)

  }  

  handleResults() {
    const [data, status, xhr] = event.detail
    this.glossaryresultsTarget.innerHTML = xhr.response
  }
  
}
import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static values = {
    urltextinput: String,
    polid: Number
  }

  connect() {
    console.log("ssss")
  }

  filter() {
    let params = new URLSearchParams()
    let i = 0

    params.append("p_polid",this.polidValue)

    get(`${this.urltextinputValue}?${params}`, {
      responseKind: "turbo-stream"
    })

  }

}
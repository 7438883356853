import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["draftcheckbox","draftdropdown"]

  connect() {
  }

  change(event) {
    if (this.draftdropdownTarget.value == "Final"){
      this.draftcheckboxTarget.checked = false
    }
    else {
      this.draftcheckboxTarget.checked = true
    }
  }
}
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["extratag","year", "subject", "technology",  "country", "policytype", "sector", "form" ]

  
  connect(){
   console.log("Here 12346")
  }
  

  cleartagpolicyfamily() {
   document.getElementById("p_tag_policy_family").selectedIndex=0;
  }
  cleartagpolicytype() {
   document.getElementById("p_tag_policy_type").selectedIndex=0;
  }
  cleartagpolicycategory() {
   document.getElementById("p_tag_policy_category").selectedIndex=0;
  }

  cleariso3() {
   document.getElementById("p_iso3").selectedIndex=0;
  }
  
  clearpoltype() {
   document.getElementById("p_poltype").selectedIndex=0;
  }

  clearsector() {
   document.getElementById("p_sector").selectedIndex=0;
  }

  cleartechnology() {
   document.getElementById("p_technology").selectedIndex=0;
  }

  clearsubject() {
   document.getElementById("p_subject").selectedIndex=0;
  }

  clearextratag() {
   document.getElementById("p_extratag").selectedIndex=0;
  }

  clearyear() {
   document.getElementById("year").selectedIndex=0;
  }

  clearpolicystatus() {
   document.getElementById("p_policy_status").selectedIndex=0;
  }

  cleardraftingstatus() {
   document.getElementById("p_drafting_status").selectedIndex=0;
  }

}
import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["statedropdown","jurisdictiondropdown","statusdropdown","yearstart","yearend"]
  static values = {
    url: String,
    param: String,
    year: Number
  }

  connect() {
    if (this.statedropdownTarget.id === "") {
      this.statedropdownTarget.id = Math.random().toString(36)
    }
  }

  change(event) {
    console.log("ssss")
    console.log(event.target.selectedOptions[0].value)
    let params = new URLSearchParams()
    if (event.target.selectedOptions.length == 1){
      params.append(this.paramValue, event.target.selectedOptions[0].value)
    }
    params.append("target", this.statedropdownTarget.id)
    //Enale or disable dropdown based on the jurisdiction
    if (this.jurisdictiondropdownTarget.value == "State/Provincial"){
      params.append("dd_active", 1)
    }
    else {
      params.append("dd_active", 0)
    }

    get(`${this.urlValue}?${params}`, {
      responseKind: "turbo-stream"
    })
    this.showhide(event)
  }

  showhide(event) {
    if (this.jurisdictiondropdownTarget.value == "State/Provincial"){
      this.statedropdownTarget.disabled = false
    }
    else {
      this.statedropdownTarget.disabled = true
    }

  }
  
  statuschange(event) {

    if (this.yearstartTarget.value > this.yearValue && this.yearstartTarget.value > 0){
      this.statusdropdownTarget.value = "Planned"
    }
    else if (this.yearendTarget.value < this.yearValue && this.yearendTarget.value > 0 ){
      this.statusdropdownTarget.value = "Ended"
    }

  }

  
}
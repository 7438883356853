import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "name", "counter","btnsubmit","charcounterinfo" ]


  initialize() {
    this.countCharacters()
  }
  countCharacters(event) {
    let characters = this.nameTarget.value.length;
    if (characters <1) {
        this.btnsubmitTarget.disabled = true;
    } else {
        this.btnsubmitTarget.disabled = false;
    }
    this.counterTarget.innerText = characters;
    if (characters > 280) {
      this.nameTarget.classList.add("text-danger")
      this.charcounterinfoTarget.classList.add("text-danger")
      //this.charcounterinfoTarget.classList.add("fw-bold")
    } else {
      this.nameTarget.classList.remove("text-danger")
      this.charcounterinfoTarget.classList.remove("text-danger")
      //this.charcounterinfoTarget.classList.remove("fw-bold")
    }
  }
  reset() {
    this.element.reset();
    this.btnsubmitTarget.disabled = true;
    this.counterTarget.innerText = 0;
    var field_with_errors = document.querySelectorAll('.field_with_errors'),i;
    if (field_with_errors.length > 0) {
        for (i = 0; i < field_with_errors.length; i++) {
                field_with_errors[i].className = field_with_errors[i].classList.remove("field_with_errors");
            }
    }
  }
}
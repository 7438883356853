// pagy_controller.js - a stimulus JS controller
import { Controller } from "stimulus"

export default class extends Controller {
//  static targets = ["form", "submitButton", "pageInput","poltype" ]

  connect() {
    clearTimeout(this.timeout)

    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 2)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 5)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 10)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 20)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 30)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 50)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 100)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 200)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 500)
    this.timeout = setTimeout(() => {
      this.adjust_link();
    }, 1000)

    
  }
  adjust_link() {
    console.log("Here pagy")
  //window.history.pushState(null, null, "/");
  }

}
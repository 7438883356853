import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["policystatus","draftingstatus","extratag","year", "subject", "technology",  "country", "policytype", "sector", "form","tagpolicyfamily","tagpolicytype","tagpolicycategory" ]

  
  search() {
    console.log("Im here")


    clearTimeout(this.timeout)
    this.timeout = setTimeout(() => {
      this.formTarget.commit.click();
    }, 1)
    
  }

  clear() {

    this.tagpolicyfamilyTarget.selectedIndex = 0;
    this.tagpolicytypeTarget.selectedIndex = 0;
    this.tagpolicycategoryTarget.selectedIndex = 0;
    this.extratagTarget.selectedIndex = 0;
    this.yearTarget.selectedIndex = 0;
    this.subjectTarget.selectedIndex = 0;
    this.technologyTarget.selectedIndex = 0;
    this.countryTarget.selectedIndex = 0;
    this.policytypeTarget.selectedIndex = 0;
    this.sectorTarget.selectedIndex = 0;
    this.policystatusTarget.selectedIndex = 0;
    this.draftingstatusTarget.selectedIndex = 0;
    //this.formTarget.commit.click();
    this.search();
  }
  

  adjust_link_pagy() {
  console.log("Im here too PAGY")

  var links = document.querySelectorAll('.pagination a');
    for(var i = 0; i < links.length; i++){
      if (links[i].href.search("/search") > 0){
       links[i].href = links[i].href.replace("/search", "/")
       
        for (let j = 0; j < this.countryTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_iso3%5B%5D=" + this.countryTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.policytypeTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_poltype%5B%5D=" + this.policytypeTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.sectorTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_sector%5B%5D=" + this.sectorTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.technologyTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_technology%5B%5D=" + this.technologyTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.subjectTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_subject%5B%5D=" + this.subjectTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.yearTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_year%5B%5D=" + this.yearTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.extratagTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_extratag%5B%5D=" + this.extratagTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.policystatusTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_policy_status%5B%5D=" + this.policystatusTarget.selectedOptions[j].value.toString()
        }

        for (let j = 0; j < this.draftingstatusTarget.selectedOptions.length; j++) {
          links[i].href = links[i].href + "&p_drafting_status%5B%5D=" + this.draftingstatusTarget.selectedOptions[j].value.toString()
        }
        //console.log(links[i].href)
      }
    }

  }
  adjust_link_url() {
  console.log("Im here too URL")
  window.history.pushState(null, null, "/");


  }

}
import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["textinput","dropdown"]
  static values = {
    urltextinput: String,
    param: String,
    year: Number
  }

  connect() {
  }

  filter() {
    let params = new URLSearchParams()
    let i = 0
    let dd = document.getElementById(this.dropdownTarget.id)

    params.append(this.paramValue, this.textinputTarget.value)
    params.append("target", this.dropdownTarget.id)

    if (dd.value != ''){
          for (i = 0; i < dd.selectedOptions.length; i++) {
            params.append("selsp[]", dd.selectedOptions[i].value)
        }

    }

    get(`${this.urltextinputValue}?${params}`, {
      responseKind: "turbo-stream"
    })

  }

  filter_dd(event) {
    let params = new URLSearchParams()
    let i = 0

    params.append(this.paramValue, this.textinputTarget.value)
    params.append("target", this.dropdownTarget.id)

    for (i = 0; i < event.target.selectedOptions.length; i++) {
            console.log(event.target.selectedOptions[i].value)
            params.append("selsp[]", event.target.selectedOptions[i].value)
        }

    get(`${this.urltextinputValue}?${params}`, {
      responseKind: "turbo-stream"
    })

  }


}
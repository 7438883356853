import { Controller } from "stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = ["countrydropdown","profiledropdown","profileextratagdropdown","profilepoltypedropdown","profilesubjectdropdown","profiletechdropdown","profilesectordropdown"]
  static values = {
    url: String,
    param: String,
    year: Number
  }

  connect() {

  }


  showhide(event) {
    if (this.profiledropdownTarget.value == "country"){
      this.countrydropdownTarget.classList.remove("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")

      this.countrydropdownTarget.disabled         = false
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = true
    }
    else if (this.profiledropdownTarget.value == "tags"){
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.remove("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")

      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = false
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = true
    }
    else if (this.profiledropdownTarget.value == "poltype"){
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.remove("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")

      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = false
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = true
    }
    else if (this.profiledropdownTarget.value == "tech"){
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.remove("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")

      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = false
      this.profilesectordropdownTarget.disabled   = true
    }
    else if (this.profiledropdownTarget.value == "sector"){
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.remove("d-none")

      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = false
    }
    else if (this.profiledropdownTarget.value == "subject"){
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.remove("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")

      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = false
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = true
    }

    else  {
      this.countrydropdownTarget.classList.add("d-none")
      this.profileextratagdropdownTarget.classList.add("d-none")
      this.profilepoltypedropdownTarget.classList.add("d-none")
      this.profilesubjectdropdownTarget.classList.add("d-none")
      this.profiletechdropdownTarget.classList.add("d-none")
      this.profilesectordropdownTarget.classList.add("d-none")
 
      this.countrydropdownTarget.disabled         = true
      this.profileextratagdropdownTarget.disabled = true
      this.profilepoltypedropdownTarget.disabled  = true
      this.profilesubjectdropdownTarget.disabled = true
      this.profiletechdropdownTarget.disabled     = true
      this.profilesectordropdownTarget.disabled   = true
   }
  }
  

}